import Overlay from 'Component/Overlay';
import {
    SearchOverlayComponent as SourceSearchOverlayComponent,
} from 'SourceComponent/SearchOverlay/SearchOverlay.component';
import {ReactElement} from 'Type/Common.type';

/** @namespace Steinkrueger/Component/SearchOverlay/Component */
export class SearchOverlayComponent extends SourceSearchOverlayComponent {
    render(): ReactElement {
        const { isHideOverlay, searchCriteria } = this.props;
        const formattedSearchCriteria = searchCriteria.trim();

        if (!formattedSearchCriteria || formattedSearchCriteria.length < 2) {
            return null;
        }

        if (isHideOverlay) {
            return (
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                </article>
            );
        }

        return (
            <Overlay
              id="search"
              mix={ { block: 'SearchOverlay' } }
            >
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                </article>
            </Overlay>
        );
    }
}

export default SearchOverlayComponent;
