/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import Field from 'Component/Field';
import {FieldType} from 'Component/Field/Field.config';
import {
    ProductAttributeValueComponent as SourceProductAttributeValueComponent,
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import {ReactElement} from 'Type/Common.type';

import './ProductAttributeValue.override.style';

/** @namespace Steinkrueger/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValueComponent {
    renderDropdown(value: string, subLabel: string): ReactElement {
        const { isSelected } = this.props;
        const isDisabled = subLabel === '0';

        return (
            <Field
              type={ FieldType.CHECKBOX }
              attr={ {
                  id: value,
                  name: value,
                  checked: isSelected,
                  disabled: isDisabled,
              } }
              label={ this.getCheckboxLabel(value) }
              mix={ {
                  block: 'ProductAttributeValue',
                  elem: 'Text',
                  mods: { isSelected, isDisabled },
              } }
            />
        );
    }

    getCheckboxLabel(value: string): ReactElement {
        return (
            <div
              block="ProductAttributeValue"
              elem="Label"
            >
                { this.renderCheckboxValue(value) }
            </div>
        );
    }

    render(): ReactElement {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute) || undefined;
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex={ -1 }
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </span>
            );
        }

        const deliveryDateOption = {
            isNotAvailable: true,
        };

        const attributeOption = attribute_value ? this.getOptionLabel(attribute_value) : '';

        if (attributeOption && attributeOption.count) {
            deliveryDateOption.isNotAvailable = false;
        }

        if (attribute_code === 'delivery_date') {
            const isDeliveryNotAvailable = deliveryDateOption.isNotAvailable;

            return (
                <>
                    { /* eslint-disable-next-line react/forbid-elements */ }
                    <a
                      href={ href }
                      block="ProductAttributeValue"
                      mods={ { isDeliveryNotAvailable } }
                      onClick={ this.clickHandler }
                      aria-hidden={ isDeliveryNotAvailable }
                      mix={ mix }
                    >
                        { this.renderAttributeByType() }
                    </a>
                </>
            );
        }

        return (
            <>
                { /* eslint-disable-next-line react/forbid-elements */ }
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </a>
            </>
        );
    }
}

export default ProductAttributeValueComponent;
