/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { AMASTY_OVERLAY } from '../../component/MultipleWishlist/MultipleWishlist.config';
import MultipleWishlistSearchItem from '../../component/MultipleWishlistSearchItem';

export const changeToMultipleWishlistSearchItem = (args, callback) => {
    const isOverlay = BrowserDatabase.getItem(AMASTY_OVERLAY) === 'true';

    if (!isOverlay) {
        return callback(...args);
    }

    const [product, i] = args;

    return (
        <MultipleWishlistSearchItem
          product={ product }
          key={ i }
        />
    );
};

export default {
    'Component/SearchOverlay/Component': {
        'member-function': {
            renderSearchItem: changeToMultipleWishlistSearchItem
        }
    }
};
