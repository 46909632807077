import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchOverlayContainer as SourceSearchOverlayContainer,
} from 'SourceComponent/SearchOverlay/SearchOverlay.container';
import {RootState} from 'Util/Store/Store.type';

/** @namespace Steinkrueger/Component/SearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
});

/** @namespace Steinkrueger/Component/SearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Steinkrueger/Component/SearchOverlay/Container */
export class SearchOverlayContainer extends SourceSearchOverlayContainer {
    makeSearchRequest(): void {
        const {
            makeSearchRequest,
            clearSearchResults,
            searchCriteria,
        } = this.props;

        if (searchCriteria) {
            clearSearchResults();
            const search = encodeURIComponent(searchCriteria.trim().replace(/%/g, '%25'));

            makeSearchRequest({
                args: {
                    search,
                    filter: {
                        companyAvailability: [],
                    },
                    pageSize: 24,
                    currentPage: 1,
                },
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOverlayContainer);
