import { ElementType, useEffect } from 'react';
import { Reducer } from 'redux';

import { Props, WithReducersResult } from 'Util/DynamicReducer/DynamicReducer.type';
import injectReducers from 'Util/DynamicReducer/Helper';
import { getStore } from 'Util/Store';

/** @namespace Steinkrueger/Util/DynamicReducer/Index/withReducers */

export const withReducers = (reducers: Record<string, Reducer>) => (
    WrappedComponent: ElementType,
): WithReducersResult => {
    injectReducers(getStore(), reducers);

    const injectAndExecute = (props: Props) => {
        useEffect(() => {
            injectReducers(getStore(), reducers);
        }, [props]);

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return (
            <WrappedComponent { ...props } />
        );
    };

    return injectAndExecute;
};

export default { withReducers };
