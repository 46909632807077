/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import ProductPrice from 'Component/ProductPrice';
import SearchItemComponent from 'Component/SearchItem/SearchItem.component';
import TextPlaceholder from 'Component/TextPlaceholder';

/** @namespace Scandiweb/AmastyMultipleWishlist/Component/MultipleWishlistSearchItem/Component */
export class MultipleWishlistSearchItemComponent extends SearchItemComponent {
    static propTypes = {
        ...SearchItemComponent.propTypes,
        handleAddProduct: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
    };

    renderSku() {
        const { product: { sku }, isMobile } = this.props;

        if (!sku || isMobile) {
            return null;
        }

        return (
            <div block="MultipleWishlistSearchItem" elem="Content">
                <div block="MultipleWishlistSearchItem" elem="Sku">
                    { __('SKU:') }
                </div>
                { sku }
            </div>
        );
    }

    renderAddButton() {
        const { handleAddProduct, product: { sku } } = this.props;

        if (!sku) {
            return null;
        }

        return (
            <button
              block="MultipleWishlistSearchItem"
              elem="AddButton"
              type="submit"
              onClick={ handleAddProduct }
            >
              { __('Add') }
            </button>
        );
    }

    renderQuantityFieldInput() {
        const {
            product: { sku },
            quantity,
            handleChangeQuantity,
            isMobile,
        } = this.props;

        if (!sku || isMobile) {
            return null;
        }

        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              min={ 1 }
              value={ quantity }
              mix={ {
                  block: 'WishlistItem',
                  elem: 'QuantityInput',
                  mix: { block: 'Field', mods: { style: 'inline' } },
              } }
              onChange={ handleChangeQuantity }
            />
        );
    }

    renderQuantityField() {
        const {
            product: { sku },
            isMobile,
        } = this.props;

        if (!sku || isMobile) {
            return null;
        }

        if (!isMobile) {
            return this.renderQuantityFieldInput();
        }

        return (
            <div block="WishlistItem" elem="QuantityWrapper">
                <span block="WishlistItem" elem="QuantityText">{ __('Qty:') }</span>
                { this.renderQuantityFieldInput() }
            </div>
        );
    }

    renderProductPrice() {
        const { product: { price_range } } = this.props;

        if (!price_range) {
            return null;
        }

        return (
            <ProductPrice
              price={ price_range }
              mix={ { block: 'MultipleWishlistSearchItem', elem: 'Price' } }
            />
        );
    }

    renderContent() {
        const { product: { name } } = this.props;

        return (
            <figcaption block="MultipleWishlistSearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <h4 block="MultipleWishlistSearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </h4>
                { this.renderQuantityField() }
                { this.renderSku() }
                { this.renderProductPrice() }
                { this.renderAddButton() }
            </figcaption>
        );
    }

    renderLink() {
        return (
                <figure
                  block="SearchItem"
                  elem="Wrapper"
                >
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
        );
    }

    render() {
        return (
            <div block="MultipleWishlistSearchItem">
                <li block="SearchItem">
                    { this.renderLink() }
                </li>
            </div>
        );
    }
}

export default MultipleWishlistSearchItemComponent;
