/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const tabMap = {
    0: 'wishlist',
    1: 'requisition',
};

export const breadcrumbsMap = {
    0: __('My Wish Lists'),
    1: __('My Requisition Lists'),
};

export const WISH_LISTS_LOCATION = '/wishlists';

export const WISHLISTS_POPUP_ID = 'wishlists';
export const WISHLIST_MOVE = 'move';
export const MAX_IMAGES_IN_WISHLIST = 3;
export const AMASTY_WISHLIST = 'AmastyWishlist';
export const AMASTY_PRODUCT = 'AmastyProduct';
export const AMASTY_OPERATION = 'AmastyWishlistOperation';
export const AMASTY_SHARE = 'AmastyShare';
export const AMASTY_OVERLAY = 'AmastyOverlay';
