/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchItemContainer,
} from 'Component/SearchItem/SearchItem.container';
import BrowserDatabase from 'Util/BrowserDatabase';

import { AMASTY_PRODUCT } from '../MultipleWishlist/MultipleWishlist.config';
import MultipleWishlistSearchItem from './MultipleWishlistSearchItem.component';

/** @namespace Scandiweb/AmastyMultipleWishlist/Component/MultipleWishlistSearchItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps,
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Scandiweb/AmastyMultipleWishlist/Component/MultipleWishlistSearchItem/Container */
export class MultipleWishlistSearchItemContainer extends SearchItemContainer {
    static propTypes = {
        ...SearchItemContainer.propTypes,
        isMobile: PropTypes.bool.isRequired,
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleAddProduct: this.handleAddProduct.bind(this),
        handleChangeQuantity: this.handleChangeQuantity.bind(this)
    };

    state = {
        quantity: 1,
    };

    handleAddProduct() {
        const { product } = this.props;
        const { quantity } = this.state;

        BrowserDatabase.setItem({ product, quantity }, AMASTY_PRODUCT);
    }

    handleChangeQuantity(quantity) {
        this.setState({ quantity });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class
    containerProps = () => ({
        linkTo: this.getLinkTo(),
        imgSrc: this.getImgSrc(),
        customAttribute: this.getCustomAttribute(),
        ...this.props,
        ...this.state,
    });

    render() {
        return (
            <MultipleWishlistSearchItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleWishlistSearchItemContainer);
